import styled, { css } from "styled-components";
import { Link } from "..";

export const SlideContentWrapper = styled.div`
  display: grid;
  grid-area: content;
  @media screen and (min-width: 1128px) {
    grid-template-columns: 1fr;
    grid-template-rows: 250px auto;
  }
`;

export const TitleWrapper = styled.div`
  padding: var(--spacingS) 0;

  > h1 {
    color: var(--primary800);
    font-weight: normal;
    text-align: center;
    margin: 0;
    font-size: 38px;
    line-height: 48px;
  }

  a,
  button {
    display: none;
  }

  @media screen and (min-width: 1128px) {
    position: absolute;
    right: 0px;
    top: 50px;
    display: flex;
    flex-direction: column;
    gap: var(--spacingM);
    max-width: 50%;
    padding: 0;

    > h1 {
      height: auto;
      font-size: 46px;
      font-weight: 300;
      padding: var(--spacingXs) var(--spacingM);
      background-color: var(--secondary100);
      border-radius: var(--radiusS);
      line-height: 54px;
      text-align: right;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    a,
    button {
      display: block;
      align-self: flex-end;
    }
  }
`;

export const SlideTitleWrapper = styled.div`
  ${
    "" /* display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end; */
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: var(--textS);
  line-height: var(--line-heightS);

  > p {
    margin: 0px;
  }

  @media screen and (min-width: 1128px) {
    font-size: var(--textM);
    line-height: var(--lineHeightM);
    align-self: end;
    justify-content: space-between;
    margin-bottom: var(--spacingS);
  }
`;

export const BasicSlideWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
    height: 160px;
    max-height: 500px;
    object-fit: cover;
    grid-area: image;
    border-radius: var(--radius24);
    overflow: hidden;
    margin-top: var(--spacingM);
  }

  @media screen and (min-width: 1128px) {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-template-rows: auto;
    grid-template-areas: "image content";
    grid-gap: var(--spacingM);

    img {
      margin: 0;
      height: 100%;
      border-radius: var(--radiusXL);
    }

    ${DescriptionWrapper} {
      justify-self: flex-start;
      text-align: left;
    }

    ${({ reversed }) =>
      reversed &&
      css`
        grid-template-columns: 4fr 8fr;
        grid-template-areas: "content image";

        ${TitleWrapper} {
          left: 0px;
          > h1 {
            text-align: left;
          }
          a,
          button {
            align-self: flex-start;
          }
        }

        ${DescriptionWrapper} {
          justify-self: flex-end;
          text-align: right;
        }
      `}
  }
`;

export const ButtonMobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--spacingM);

  a,
  button {
    width: fit-content;
  }

  @media screen and (min-width: 1128px) {
    display: none;
  }
`;
